<template>
  <v-card>
    <v-toolbar color="grey darken-3" dark elevation="1">
      <v-toolbar-title>
        {{ title }}
      </v-toolbar-title>
      <v-spacer></v-spacer>
      <v-menu offset-y v-model="menu">
        <template v-slot:activator="{ on, attrs }">
          <v-btn
              class="grey darken-3"
              icon
              outlined
              :disabled="isNew"
              v-bind="attrs"
              v-on="on"
              :loading="getModalLoading"
              @click="menu = true"
          >
            <v-icon color="secondary">mdi-arrow-down-drop-circle-outline</v-icon>
          </v-btn>
        </template>
        <v-list dense rounded nav>
          <v-list-item link class="px-4" @click.stop="deleteDialog">
            <v-list-item-icon>
              <v-icon color="error lighten-1">mdi-delete-forever-outline</v-icon>
            </v-list-item-icon>
            <v-list-item-title class="font-weight-medium">Вилучити</v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>
    </v-toolbar>
    <v-card-text class="pt-4">
      <v-form v-model="formValid" ref="form">
        <v-row>
          <v-col cols="12" md="12">
            <v-select
                filled
                :items="person_select"
                v-model="privilege_owner_id"
                hide-details
                label="Власник посвідчення"
                :class="privilege_owner_id ? '' : 'req-star'"
                color="grey"
            />
          </v-col>
          <v-col cols="12" md="6">
            <v-text-field type="text" filled label="Серія та № посвідчення" v-model="privilege_number"
                          hide-details
                          required :rules="[v => !!v || 'Це поле є обов’язковим']"
                          :class="privilege_number ? '' : 'req-star'"
                          color="grey"/>
          </v-col>
          <v-col cols="12" md="6">
            <v-text-field type="text" filled label="Порядковий номер" v-model="privilege_order"
                          hide-details
                          required :rules="[v => !!v || 'Це поле є обов’язковим']"
                          :class="privilege_order ? '' : 'req-star'"
                          color="grey"/>
          </v-col>
          <v-col cols="12" md="12">
            <AC_PrivilegeCategory
                v-model="privilege_category_id"
                filled
                required :rules="[v => !!v || 'Це поле є обов’язковим']"
                :class="privilege_category_id ? '' : 'req-star'"
                @autocompleteChange="changePrivilegeCategory"
            />
          </v-col>
          <v-col cols="12" md="6">
            <date-component v-model="date_start" req label="Дата початку" :class_="!date_start ? 'req-star' : ''"/>
          </v-col>
          <v-col cols="12" md="6">
            <date-component v-model="date_end" label="Дата закінчення"/>
          </v-col>
        </v-row>
      </v-form>
    </v-card-text>
    <v-card-actions>
      <v-spacer></v-spacer>
      <v-btn depressed text color="secondary darken-1" @click.stop="crud_item" :loading="getModalLoading"
             class="button-accept">
        <v-icon left>mdi-content-save</v-icon>
        Зберегти
      </v-btn>
      <v-btn depressed text tile color="grey" @click="closeModal">
        <v-icon left>mdi-close</v-icon>
        Відмінити
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import {
  UPDATE_PRIVILEGE_OWNER, CREATE_PRIVILEGE_OWNER, REMOVE_PRIVILEGE_OWNER
} from "@/store/actions/privilege";

import {ALERT_SHOW} from "@/store/actions/alert";
import {QUESTION_SHOW} from "@/store/actions/question";
import familyAPI from "@/utils/axios/family";

import ModalComponentMixin from "@/mixins/modal_component";

const modalDeleteId = 'privilege_category_owner_modal_delete'

export default {
  name: "HWP_Modal_PrivilegeOwner",
  mixins: [ModalComponentMixin],
  components: {
    AC_PrivilegeCategory: () => import("@/components/autocomplite/AC_PrivilegeCategory")
  },
  data() {
    return {
      flat_hash: this.item.flat_hash || null,
      privilege_owner_id: this.item.privilege_owner_id || null,
      privilege_number: this.privilege_number,
      privilege_category_id: this.privilege_category_id || null,
      privilege_order: this.privilege_order,
      date_start: this.item.date_start,
      date_end: this.item.date_end,
      person_select: []
    }
  },
  methods: {
    changePrivilegeCategory(payload) {
      this.privilege_category_id = payload ? payload.value : null
    },
    closeModal() {
      this.$emit('closeModal')
      this.itemId = this.item.id
      this.flat_hash = this.item.flat_hash || null
      this.privilege_owner_id = this.item.privilege_owner_id || null
      this.privilege_number = this.item.privilege_number
      this.privilege_category_id = this.item.privilege_category_id || null
      this.privilege_order = this.item.privilege_order
      this.date_start = this.item.date_start
      this.date_end = this.item.date_end

      if (this.$refs.form) {
        this.$refs.form.resetValidation()
      }
    },
    deleteDialog() {
      this.menu = false
      const payload = {
        text: `Підтвердіть вилучення власника пільги`,
        accept_button: true,
        id: modalDeleteId
      }
      this.$store.dispatch(QUESTION_SHOW, payload)
    },
    crud_item() {
      this.formValid = this.$refs.form.validate()
      if (!this.formValid) {
        this.formValid = false
        this.$store.commit(ALERT_SHOW, {
          text: 'Назва має бути заповнена',
          color: 'error lighten-1'
        })
        return
      }

      const payload = {
        flat_hash: this.flat_hash,
        privilege_owner_id: this.privilege_owner_id,
        privilege_number: this.privilege_number,
        privilege_category_id: this.privilege_category_id,
        order: this.privilege_order,
        date_start: this.date_start,
        date_end: this.date_end || null
      }

      if (this.isNew) {
        this.$store.dispatch(CREATE_PRIVILEGE_OWNER, payload)
            .then(el => {
              if (el) {
                this.closeModal()
              }
            })
      } else {
        payload.id = this.itemId
        this.$store.dispatch(UPDATE_PRIVILEGE_OWNER, payload)
            .then(el => {
              if (el) {
                this.closeModal()
              }
            })
      }
    },
    watch_item() {
      this.watcher = this.$watch(
          'item',
          {
            deep: true,
            immediate: true,
            handler(payload) {
              this.itemId = payload.id
              this.flat_hash = payload.flat_hash || null
              this.privilege_owner_id = payload.privilege_owner_id || null
              this.privilege_number = payload.privilege_number
              this.privilege_category_id = payload.privilege_category_id || null
              this.privilege_order = payload.privilege_order
              this.date_start = payload.date_start
              this.date_end
            }
          }
      )
    },
    watch_modal_answer() {
      this.delete_watcher = this.$watch(
          'modalAnswer',
          {
            handler(payload) {
              if (payload.id === modalDeleteId) {
                if (payload.answer) {
                  this.$store.dispatch(REMOVE_PRIVILEGE_OWNER, this.itemId)
                    .then(ok => {
                      if (ok) {
                        this.closeModal()
                      }
                    })
                }
              }
            }
          }
      )
    },
    watch_dialog() {
      this.dialog_watcher = this.$watch(
          'dialog',
          {
            immediate: true,
            handler(payload) {
              if (payload) {
                this.watch_item()
                this.watch_modal_answer()
                familyAPI.by_flat_hash_select(this.flat_hash)
                    .then(response => response.data)
                    .then(data => {
                      this.person_select = data
                    })
              } else {
                if (this.watcher) {
                  this.watcher()
                }
                if (this.delete_watcher) {
                  this.delete_watcher()
                }
              }
            }
          }
      )
    }
  }
}
</script>

